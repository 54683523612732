import {
  IconBed,
  IconHeart,
  IconHeartOutline,
  IconMapMarkerOutline,
  IconPhoto,
  IconShower,
  IconStairs,
  IconStickerText,
  IconTagOutline,
  IconVectorSquareClose,
  IconVideo,
} from 'components/icons';
import { useAuthenticationContext } from 'components/providers/authentication-provider';
import ModalWrapper from 'components/shared/modal-wrapper';
import {
  FileWithFullUrls,
  GET_REAL_ESTATE_DETAILS,
  GetRealEstateDetailsData,
  GetRealEstateDetailsVariables,
  RealEstate,
  YoutubeFile,
} from 'graphql/main/queries';
import { LeadRealEstateStatus } from 'graphql/main/queries/get-list-lead-real-estates';
import { useFlexLazyQuery, useModal, useTranslation } from 'hooks';
import {
  filter,
  gt,
  includes,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  map,
  reduce,
  toNumber,
  upperFirst,
} from 'lodash';
import moment from 'moment-timezone';
import Link from 'next/link';
import { Fragment, MouseEvent, useState } from 'react';
import { convertNumberToVietnameseCurrencyShortString } from 'utils';
import SaveRealEstateModal from '../save-real-estate-modal';

interface Props {
  size?: 'medium' | 'small';
  type?: 'normal' | 'vip' | 'authorized' | 'special' | 'deposit' | 'foreclosure' | 'lead';
  realEstate?: RealEstate;
}

const RealEstateHorizontalCard = ({ size, type, realEstate }: Props) => {
  const translation = useTranslation();
  const [privateRealEstate, setPrivateRealEstate] = useState(realEstate);
  const isSizeSmall = isEqual(size, 'small');
  const { currentUser } = useAuthenticationContext();
  const [getRealEstateDetails] = useFlexLazyQuery<
    GetRealEstateDetailsData,
    GetRealEstateDetailsVariables
  >('main', GET_REAL_ESTATE_DETAILS);
  const mediaCountObjects = [
    {
      types: ['video', 'youtube'],
      icon: <IconVideo className='h-[16px] w-[16px]' />,
      count: 0,
    },
    {
      types: ['image'],
      icon: <IconPhoto className='h-[16px] w-[16px]' />,
      count: 0,
    },
  ];
  const smallPropertyObjects = [
    {
      icon: <IconVectorSquareClose className='h-[20px] w-[20px] text-text2' />,
      content: privateRealEstate?.area
        ? `${privateRealEstate?.area.toLocaleString()} m\u00B2`
        : '-',
    },
    {
      icon: <IconShower className='h-[20px] w-[20px] text-text2' />,
      content: privateRealEstate?.toiletCount
        ? privateRealEstate?.toiletCount.toLocaleString()
        : '-',
    },
    {
      icon: <IconBed className='h-[20px] w-[20px] text-text2' />,
      content: privateRealEstate?.bedroomCount
        ? privateRealEstate?.bedroomCount.toLocaleString()
        : '-',
    },
  ];
  const mediumPropertyObjects = [
    {
      icon: <IconVectorSquareClose className='h-[20px] w-[20px] text-text2' />,
      content: privateRealEstate?.area
        ? `${privateRealEstate?.area.toLocaleString()} m\u00B2`
        : '-',
    },
    {
      icon: <IconShower className='h-[20px] w-[20px] text-text2' />,
      content: privateRealEstate?.toiletCount
        ? privateRealEstate?.toiletCount.toLocaleString()
        : '-',
    },
    {
      icon: <IconBed className='h-[20px] w-[20px] text-text2' />,
      content: privateRealEstate?.bedroomCount
        ? privateRealEstate?.bedroomCount.toLocaleString()
        : '-',
    },
    {
      icon: <IconStairs className='h-[20px] w-[20px] text-text2' />,
      content: privateRealEstate?.floorCount
        ? `${privateRealEstate?.floorCount.toLocaleString()} tầng`
        : '-',
    },
  ];
  const { showing: showingSavePostModal, toggle: toggleSavePostModal } = useModal();
  const images = filter(privateRealEstate?.mediaUrls, (mediaUrl: FileWithFullUrls | YoutubeFile) =>
    isEqual(mediaUrl.type, 'image'),
  );

  const save = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    toggleSavePostModal();
  };
  const completeSavingRealEstate = async () => {
    const { data } = await getRealEstateDetails({
      variables: {
        id: realEstate?.id,
      },
    });
    const { getRealEstateDetails: _realEstate } = data ?? {};
    setPrivateRealEstate(_realEstate);
  };

  return (
    <Fragment>
      {isSizeSmall ? (
        <Link href={!privateRealEstate?.fullSlug ? '#' : `/${privateRealEstate?.fullSlug}`}>
          <div className='group flex w-full flex-col space-y-[12px]'>
            <div className='line-clamp-2 min-h-[20px] space-x-[8px]'>
              {includes(
                ['authorized', 'deposit', 'foreclosure', 'lead', 'special', 'vip'],
                type,
              ) && (
                <div className='float-left flex items-center space-x-[8px]'>
                  {includes(['authorized', 'deposit', 'foreclosure', 'lead'], type) && (
                    <span className='whitespace-nowrap rounded-[4px] bg-accent-sale px-[8px] py-[4px] text-[11px] font-[500] uppercase leading-[12px] text-paper'>
                      {isEqual(type, 'authorized')
                        ? 'chính chủ'
                        : isEqual(type, 'deposit')
                        ? 'ký gửi'
                        : isEqual(type, 'lead')
                        ? 'đầu chủ'
                        : 'phát mãi'}
                    </span>
                  )}
                  {includes(['special', 'vip'], type) && (
                    <span className='whitespace-nowrap rounded-[4px] bg-accent-sale px-[8px] py-[4px] text-[11px] font-[500] uppercase leading-[12px] text-paper'>
                      {isEqual(type, 'special') ? 'vip đặc biệt' : 'vip 1'}
                    </span>
                  )}
                  {isEqual(type, 'lead') &&
                    realEstate?.leadREStatus &&
                    includes(
                      [
                        LeadRealEstateStatus.sold,
                        LeadRealEstateStatus.onSell,
                        LeadRealEstateStatus.inDispute,
                        LeadRealEstateStatus.locked,
                      ],
                      realEstate.leadREStatus,
                    ) && (
                      <span className='whitespace-nowrap rounded-[4px] bg-accent-sale px-[8px] py-[4px] text-[11px] font-[500] uppercase leading-[12px] text-paper'>
                        {translation.major.leadRealEstateStatus[realEstate.leadREStatus]}
                      </span>
                    )}
                </div>
              )}
              <span
                className={`font-[600] leading-[20px] ${
                  isEqual(type, 'special')
                    ? 'text-badge'
                    : isEqual(type, 'vip')
                    ? 'text-accent-sale'
                    : isEqual(type, 'authorized') ||
                      isEqual(type, 'deposit') ||
                      isEqual(type, 'foreclosure') ||
                      isEqual(type, 'lead')
                    ? 'text-primary'
                    : ''
                }`}
              >
                {upperFirst(privateRealEstate?.postTitle)}
              </span>
            </div>
            <div className='flex space-x-[12px]'>
              <div className='relative h-[153px] min-w-[140px] max-w-[140px] overflow-hidden rounded-[8px]'>
                <img
                  src={(images?.[0] as FileWithFullUrls)?.originalUrl ?? '/images/error-image.svg'}
                  alt={privateRealEstate?.postTitle}
                  loading='lazy'
                  className='h-[153px] min-w-[140px] max-w-[140px] object-cover transition-all duration-[500ms] ease-in-out group-hover:scale-[1.1]'
                />
                {!isNil(privateRealEstate?.isForSell) && (
                  <span
                    className={`absolute left-[8px] top-[8px] rounded-[4px] px-[10px] py-[6px] text-[11px] font-[500] uppercase leading-[14px] text-paper ${
                      privateRealEstate?.isForSell ? 'bg-accent-sale' : 'bg-accent-rent'
                    }`}
                  >
                    {privateRealEstate?.isForSell ? 'bán' : 'thuê'}
                  </span>
                )}
                {!isEmpty(privateRealEstate?.mediaUrls) && (
                  <div className='absolute bottom-[5px] right-[4px] flex flex-col space-y-[4px]'>
                    {map(
                      filter(
                        reduce(
                          privateRealEstate?.mediaUrls,
                          (accumulator, mediaUrl) =>
                            map(accumulator, (item) => {
                              if (includes(item.types, mediaUrl.type)) {
                                return { ...item, count: item.count + 1 };
                              }

                              return item;
                            }),
                          mediaCountObjects,
                        ),
                        (mediaCountObject) => gt(mediaCountObject.count, 0),
                      ),
                      (mediaCountObject, mediaCountObjectIndex) => (
                        <div
                          key={mediaCountObjectIndex}
                          className='flex items-center justify-center space-x-[4px] rounded-[4px] bg-[#000000]/[.2] px-[12px] py-[3px] text-paper'
                        >
                          <span className='text-[10px] leading-[13px]'>
                            {mediaCountObject.count}
                          </span>
                          {mediaCountObject.icon}
                        </div>
                      ),
                    )}
                  </div>
                )}
              </div>
              <div className='flex w-full flex-col justify-between space-y-[19px] overflow-hidden'>
                <div className='flex min-h-[16px] items-center space-x-[24px]'>
                  <div className='flex w-full items-center space-x-[8px]'>
                    <IconTagOutline className='h-[20px] w-[20px] min-w-[20px] text-alert' />
                    <span className='w-full truncate text-[14px] font-[600] leading-[16px] text-alert'>
                      {isNil(privateRealEstate?.price) ||
                      !isNumber(toNumber(privateRealEstate?.price)) ||
                      !toNumber(privateRealEstate?.price)
                        ? 'Thương lượng'
                        : `${convertNumberToVietnameseCurrencyShortString(
                            toNumber(privateRealEstate?.price),
                          )}${privateRealEstate?.isForSell ? '' : '/tháng'}`}
                    </span>
                  </div>
                  <span className='w-full truncate text-right text-[14px] leading-[16px]'>
                    {!privateRealEstate?.isForSell ||
                    isNil(privateRealEstate?.price) ||
                    !isNumber(toNumber(privateRealEstate?.price)) ||
                    !toNumber(privateRealEstate?.price) ||
                    isNil(privateRealEstate?.area)
                      ? null
                      : `${convertNumberToVietnameseCurrencyShortString(
                          toNumber(privateRealEstate?.price) / toNumber(privateRealEstate?.area),
                        )}/m\u00B2`}
                  </span>
                </div>
                <div className='flex min-h-[16px] space-x-[24px]'>
                  {map(
                    filter(smallPropertyObjects, (propertyObject) =>
                      Boolean(propertyObject.content),
                    ),
                    (propertyObject, propertyObjectIndex) => (
                      <div key={propertyObjectIndex} className='flex items-center space-x-[10px]'>
                        {propertyObject.icon}
                        <span className='truncate text-[12px] leading-[15px]'>
                          {propertyObject.content}
                        </span>
                      </div>
                    ),
                  )}
                </div>
                <div className='flex min-h-[16px] items-center space-x-[8px]'>
                  <IconMapMarkerOutline className='h-[20px] w-[20px] text-text2' />
                  <span className='truncate text-[12px] leading-[15px]'>
                    {privateRealEstate?.shortAddress}
                  </span>
                </div>
                <div className='flex min-h-[20px] items-center justify-between space-x-[24px]'>
                  {privateRealEstate?.createdAt && (
                    <span className='w-full truncate text-[12px] leading-[15px] text-text2'>
                      {moment(privateRealEstate.createdAt).format('DD/MM/YYYY')}
                    </span>
                  )}
                  <div className='flex w-full items-center justify-end space-x-[12px]'>
                    {currentUser?.id &&
                      privateRealEstate?.creator?.id &&
                      isEqual(currentUser.id, privateRealEstate?.creator.id) &&
                      privateRealEstate?.privateNote && (
                        <IconStickerText className='h-[20px] w-[20px] text-[#F97316]' />
                      )}
                    {!isNil(currentUser) && (
                      <button
                        type='button'
                        className={`flex h-[20px] w-[20px] items-center justify-center rounded-full ${
                          privateRealEstate?.isSaved
                            ? 'bg-primary'
                            : 'border border-paper bg-[#000000]/[.2]'
                        }`}
                        onClick={save}
                      >
                        {privateRealEstate?.isSaved ? (
                          <IconHeart className='h-[12px] w-[12px] text-paper' />
                        ) : (
                          <IconHeartOutline className='h-[12px] w-[12px] text-paper' />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ) : privateRealEstate?.isForeclosure ? (
        <Link
          href={!privateRealEstate?.fullSlug ? '#' : `/${privateRealEstate?.fullSlug}`}
          className='relative'
        >
          <div className='flex w-full flex-col overflow-hidden rounded-[8px] transition-all duration-[500ms] ease-in-out hover:shadow-6'>
            <div className='relative'>
              <div className='flex h-[300px] space-x-[4px]'>
                <div className='h-full w-full overflow-hidden'>
                  <img
                    src={
                      (images?.[0] as FileWithFullUrls)?.originalUrl ?? '/images/error-image.svg'
                    }
                    alt={privateRealEstate?.postTitle}
                    loading='lazy'
                    className='h-full w-full object-cover transition-all duration-[500ms] ease-in-out hover:scale-[1.1]'
                  />
                </div>
                {images?.[1] && (
                  <div className='flex min-w-[33.33%] max-w-[33.33%] flex-col space-y-[4px]'>
                    {images?.[1] && (
                      <div className='h-full w-full overflow-hidden'>
                        <img
                          src={
                            (images?.[1] as FileWithFullUrls)?.originalUrl ??
                            '/images/error-image.svg'
                          }
                          alt={privateRealEstate?.postTitle}
                          loading='lazy'
                          className='h-full w-full object-cover transition-all duration-[500ms] ease-in-out hover:scale-[1.1]'
                        />
                      </div>
                    )}
                    {images?.[2] && (
                      <div className='flex h-full w-full space-x-[4px] overflow-hidden'>
                        {images?.[2] && (
                          <div className='h-full w-full overflow-hidden'>
                            <img
                              src={
                                (images?.[2] as FileWithFullUrls)?.originalUrl ??
                                '/images/error-image.svg'
                              }
                              alt={privateRealEstate?.postTitle}
                              loading='lazy'
                              className='h-full w-full object-cover transition-all duration-[500ms] ease-in-out hover:scale-[1.1]'
                            />
                          </div>
                        )}
                        {images?.[3] && (
                          <div className='h-full w-full overflow-hidden'>
                            <img
                              src={
                                (images?.[3] as FileWithFullUrls)?.originalUrl ??
                                '/images/error-image.svg'
                              }
                              alt={privateRealEstate?.postTitle}
                              loading='lazy'
                              className='h-full w-full object-cover transition-all duration-[500ms] ease-in-out hover:scale-[1.1]'
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className='absolute left-[10px] top-[10px] flex space-x-[8px]'>
                {!isNil(privateRealEstate?.isForSell) && (
                  <span
                    className={`rounded-[4px] px-[8px] py-[4px] text-[12px] font-[500] uppercase leading-[15px] text-paper ${
                      privateRealEstate?.isForSell ? 'bg-accent-sale' : 'bg-accent-rent'
                    }`}
                  >
                    {privateRealEstate?.isForSell ? 'bán' : 'thuê'}
                  </span>
                )}
                <span className='rounded-[4px] bg-accent-sale px-[8px] py-[4px] text-[12px] font-[500] uppercase leading-[15px] text-paper'>
                  phát mãi
                </span>
                {includes(['special', 'vip'], type) && (
                  <span className='rounded-[4px] bg-accent-sale px-[8px] py-[4px] text-[12px] font-[500] uppercase leading-[15px] text-paper'>
                    {isEqual(type, 'special') ? 'vip đặc biệt' : 'vip 1'}
                  </span>
                )}
              </div>
              <div className='absolute left-[10px] top-[70px] h-[52px] w-[59px] bg-[url("/logos/foreclosure-stamp.svg")] bg-cover bg-center bg-no-repeat' />
              {!isEmpty(privateRealEstate?.mediaUrls) && (
                <div className='absolute bottom-[11px] right-[9px] flex flex-col space-y-[6px]'>
                  {map(
                    filter(
                      reduce(
                        privateRealEstate?.mediaUrls,
                        (accumulator, mediaUrl) =>
                          map(accumulator, (item) => {
                            if (includes(item.types, mediaUrl.type)) {
                              return { ...item, count: item.count + 1 };
                            }

                            return item;
                          }),
                        mediaCountObjects,
                      ),
                      (mediaCountObject) => gt(mediaCountObject.count, 0),
                    ),
                    (mediaCountObject, mediaCountObjectIndex) => (
                      <div
                        key={mediaCountObjectIndex}
                        className='flex items-center justify-center space-x-[4px] rounded-[4px] bg-[#000000]/[.2] px-[12px] py-[3px] text-paper'
                      >
                        <span className='text-[12px] leading-[15px]'>{mediaCountObject.count}</span>
                        {mediaCountObject.icon}
                      </div>
                    ),
                  )}
                </div>
              )}
            </div>
            <div className='flex w-full flex-col space-y-[16px] overflow-hidden px-[24px] py-[16px]'>
              <span className='line-clamp-2 min-h-[46px] text-[18px] font-[600] leading-[23px] text-primary'>
                {upperFirst(privateRealEstate?.postTitle)}
              </span>
              <div className='flex h-[32px] items-center space-x-[24px]'>
                <div className='flex items-center space-x-[24px]'>
                  <div className='flex items-center space-x-[8px]'>
                    <IconTagOutline className='h-[20px] w-[20px] text-alert' />
                    <span className='whitespace-nowrap text-[18px] font-[600] leading-[23px] text-alert'>
                      {isNil(privateRealEstate?.price) ||
                      !isNumber(toNumber(privateRealEstate?.price)) ||
                      !toNumber(privateRealEstate?.price)
                        ? 'Thương lượng'
                        : `${convertNumberToVietnameseCurrencyShortString(
                            toNumber(privateRealEstate?.price),
                          )}${privateRealEstate?.isForSell ? '' : '/tháng'}`}
                    </span>
                  </div>
                  {!privateRealEstate?.isForSell ||
                    isNil(privateRealEstate?.price) ||
                    !isNumber(toNumber(privateRealEstate?.price)) ||
                    !toNumber(privateRealEstate?.price) ||
                    isNil(privateRealEstate?.area) || (
                      <Fragment>
                        <div className='h-[24px] w-[1px] bg-stroke' />
                        <span className='whitespace-nowrap text-[14px] leading-[16px]'>
                          {`${convertNumberToVietnameseCurrencyShortString(
                            toNumber(privateRealEstate?.price) / toNumber(privateRealEstate?.area),
                          )}/m\u00B2`}
                        </span>
                      </Fragment>
                    )}
                </div>
                {map(
                  filter(mediumPropertyObjects, (propertyObject) =>
                    Boolean(propertyObject.content),
                  ),
                  (propertyObject, propertyObjectIndex) => (
                    <Fragment key={propertyObjectIndex}>
                      <div className='h-[24px] w-[1px] bg-stroke' />
                      <div className='flex items-center space-x-[8px]'>
                        <div className='flex items-center space-x-[8px]'>
                          {propertyObject.icon}
                          <span className='truncate'>{propertyObject.content}</span>
                        </div>
                      </div>
                    </Fragment>
                  ),
                )}
                <div className='h-[24px] w-[1px] bg-stroke' />
                <span className='truncate'>{privateRealEstate?.fullAddress}</span>
              </div>
              <span className='line-clamp-2 h-[36px]'>{privateRealEstate?.postContent}</span>
              <div className='flex min-h-[20px] items-center justify-between space-x-[24px]'>
                {privateRealEstate?.createdAt && (
                  <span className='truncate text-[12px] leading-[15px] text-text2'>
                    {moment(privateRealEstate.createdAt).format('DD/MM/YYYY')}
                  </span>
                )}
                <div className='flex items-center space-x-[16px]'>
                  {currentUser?.id &&
                    privateRealEstate?.creator?.id &&
                    isEqual(currentUser.id, privateRealEstate?.creator.id) &&
                    privateRealEstate?.privateNote && (
                      <IconStickerText className='h-[20px] w-[20px] text-[#F97316]' />
                    )}
                  {!isNil(currentUser) && (
                    <button
                      type='button'
                      className={`flex h-[20px] w-[20px] items-center justify-center rounded-full ${
                        privateRealEstate?.isSaved
                          ? 'bg-primary'
                          : 'border border-paper bg-[#000000]/[.2]'
                      }`}
                      onClick={save}
                    >
                      {privateRealEstate?.isSaved ? (
                        <IconHeart className='h-[12px] w-[12px] text-paper' />
                      ) : (
                        <IconHeartOutline className='h-[12px] w-[12px] text-paper' />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <Link
          href={!privateRealEstate?.fullSlug ? '#' : `/${privateRealEstate?.fullSlug}`}
          className='relative'
        >
          <div className='group flex h-[230px] w-full overflow-hidden rounded-[8px] transition-all duration-[500ms] ease-in-out hover:shadow-6'>
            <div className='relative'>
              <div className='overflow-hidden'>
                <img
                  src={(images?.[0] as FileWithFullUrls)?.originalUrl ?? '/images/error-image.svg'}
                  alt={privateRealEstate?.postTitle}
                  loading='lazy'
                  className='h-[230px] min-w-[345px] max-w-[345px] object-cover transition-all duration-[500ms] ease-in-out group-hover:scale-[1.1]'
                />
              </div>
              <div className='absolute left-[10px] top-[10px] flex space-x-[8px]'>
                {!isNil(privateRealEstate?.isForSell) && (
                  <span
                    className={`rounded-[4px] px-[8px] py-[4px] text-[12px] font-[500] uppercase leading-[15px] text-paper ${
                      privateRealEstate?.isForSell ? 'bg-accent-sale' : 'bg-accent-rent'
                    }`}
                  >
                    {privateRealEstate?.isForSell ? 'bán' : 'thuê'}
                  </span>
                )}
                {includes(['authorized', 'deposit', 'lead'], type) && (
                  <span className='rounded-[4px] bg-accent-sale px-[8px] py-[4px] text-[12px] font-[500] uppercase leading-[15px] text-paper'>
                    {isEqual(type, 'authorized')
                      ? 'chính chủ'
                      : isEqual(type, 'lead')
                      ? 'đầu chủ'
                      : 'ký gửi'}
                  </span>
                )}
                {includes(['special', 'vip'], type) && (
                  <span className='rounded-[4px] bg-accent-sale px-[8px] py-[4px] text-[12px] font-[500] uppercase leading-[15px] text-paper'>
                    {isEqual(type, 'special') ? 'vip đặc biệt' : 'vip 1'}
                  </span>
                )}
                {isEqual(type, 'lead') &&
                  realEstate?.leadREStatus &&
                  includes(
                    [
                      LeadRealEstateStatus.sold,
                      LeadRealEstateStatus.onSell,
                      LeadRealEstateStatus.inDispute,
                      LeadRealEstateStatus.locked,
                    ],
                    realEstate.leadREStatus,
                  ) && (
                    <span className='rounded-[4px] bg-accent-sale px-[8px] py-[4px] text-[12px] font-[500] uppercase leading-[15px] text-paper'>
                      {isEqual(realEstate.leadREStatus, LeadRealEstateStatus.sold)
                        ? 'đã bán'
                        : isEqual(realEstate.leadREStatus, LeadRealEstateStatus.locked)
                        ? 'đã khóa'
                        : isEqual(realEstate.leadREStatus, LeadRealEstateStatus.inDispute)
                        ? 'đang tranh chấp'
                        : 'đang bán'}
                    </span>
                  )}
              </div>
              {!isEmpty(privateRealEstate?.mediaUrls) && (
                <div className='absolute bottom-[11px] right-[9px] flex flex-col space-y-[6px]'>
                  {map(
                    filter(
                      reduce(
                        privateRealEstate?.mediaUrls,
                        (accumulator, mediaUrl) =>
                          map(accumulator, (item) => {
                            if (includes(item.types, mediaUrl.type)) {
                              return { ...item, count: item.count + 1 };
                            }

                            return item;
                          }),
                        mediaCountObjects,
                      ),
                      (mediaCountObject) => gt(mediaCountObject.count, 0),
                    ),
                    (mediaCountObject, mediaCountObjectIndex) => (
                      <div
                        key={mediaCountObjectIndex}
                        className='flex items-center justify-center space-x-[4px] rounded-[4px] bg-[#000000]/[.2] px-[12px] py-[3px] text-paper'
                      >
                        <span className='text-[12px] leading-[15px]'>{mediaCountObject.count}</span>
                        {mediaCountObject.icon}
                      </div>
                    ),
                  )}
                </div>
              )}
            </div>
            <div className='flex w-full flex-col space-y-[16px] overflow-hidden px-[24px] py-[16px]'>
              <span
                className={`line-clamp-2 min-h-[46px] text-[18px] font-[600] leading-[23px] ${
                  isEqual(type, 'special')
                    ? 'text-badge'
                    : isEqual(type, 'vip')
                    ? 'text-accent-sale'
                    : isEqual(type, 'authorized') ||
                      isEqual(type, 'deposit') ||
                      isEqual(type, 'lead')
                    ? 'text-primary'
                    : ''
                }`}
              >
                {upperFirst(privateRealEstate?.postTitle)}
              </span>
              <div className='flex min-h-[28px] space-x-[24px]'>
                {map(
                  filter(mediumPropertyObjects, (propertyObject) =>
                    Boolean(propertyObject.content),
                  ),
                  (propertyObject, propertyObjectIndex) => (
                    <div key={propertyObjectIndex} className='flex items-center space-x-[8px]'>
                      <div className='flex items-center space-x-[8px]'>
                        {propertyObject.icon}
                        <span className='truncate'>{propertyObject.content}</span>
                      </div>
                    </div>
                  ),
                )}
              </div>
              <div className='flex min-h-[18px] items-center space-x-[8px]'>
                <IconMapMarkerOutline className='h-[20px] w-[20px] text-text2' />
                <span className='truncate'>{privateRealEstate?.fullAddress}</span>
              </div>
              <div className='flex min-h-[23px] items-center space-x-[12px]'>
                <div className='flex items-center space-x-[8px]'>
                  <IconTagOutline className='h-[20px] w-[20px] text-alert' />
                  <span className='truncate text-[18px] font-[600] leading-[23px] text-alert'>
                    {isNil(privateRealEstate?.price) ||
                    !isNumber(toNumber(privateRealEstate?.price)) ||
                    !toNumber(privateRealEstate?.price)
                      ? 'Thương lượng'
                      : `${convertNumberToVietnameseCurrencyShortString(
                          toNumber(privateRealEstate?.price),
                        )}${privateRealEstate?.isForSell ? '' : '/tháng'}`}
                  </span>
                </div>
                {!privateRealEstate?.isForSell ||
                  isNil(privateRealEstate?.price) ||
                  !isNumber(toNumber(privateRealEstate?.price)) ||
                  !toNumber(privateRealEstate?.price) ||
                  isNil(privateRealEstate?.area) || (
                    <span className='text-[12px] text-stroke'>{'\u2022'}</span>
                  )}
                <span className='truncate text-[14px] leading-[16px]'>
                  {!privateRealEstate?.isForSell ||
                  isNil(privateRealEstate?.price) ||
                  !isNumber(toNumber(privateRealEstate?.price)) ||
                  !toNumber(privateRealEstate?.price) ||
                  isNil(privateRealEstate?.area)
                    ? null
                    : `${convertNumberToVietnameseCurrencyShortString(
                        toNumber(privateRealEstate?.price) / toNumber(privateRealEstate?.area),
                      )}/m\u00B2`}
                </span>
              </div>
              <div className='flex min-h-[20px] items-center justify-between space-x-[24px]'>
                {privateRealEstate?.createdAt && (
                  <span className='truncate text-[12px] leading-[15px] text-text2'>
                    {moment(privateRealEstate?.createdAt).format('DD/MM/YYYY')}
                  </span>
                )}
                <div className='flex items-center space-x-[16px]'>
                  {currentUser?.id &&
                    privateRealEstate?.creator?.id &&
                    isEqual(currentUser?.id, privateRealEstate?.creator?.id) &&
                    privateRealEstate?.privateNote && (
                      <IconStickerText className='h-[20px] w-[20px] text-[#F97316]' />
                    )}
                  {!isNil(currentUser) && (
                    <button
                      type='button'
                      className={`flex h-[20px] w-[20px] items-center justify-center rounded-full ${
                        privateRealEstate?.isSaved
                          ? 'bg-primary'
                          : 'border border-paper bg-[#000000]/[.2]'
                      }`}
                      onClick={save}
                    >
                      {privateRealEstate?.isSaved ? (
                        <IconHeart className='h-[12px] w-[12px] text-paper' />
                      ) : (
                        <IconHeartOutline className='h-[12px] w-[12px] text-paper' />
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
      <ModalWrapper showing={showingSavePostModal}>
        <SaveRealEstateModal
          realEstate={privateRealEstate}
          onCompleteSaving={completeSavingRealEstate}
          onClose={toggleSavePostModal}
        />
      </ModalWrapper>
    </Fragment>
  );
};

export default RealEstateHorizontalCard;

export const RealEstateHorizontalCardSkeleton = ({ size }: Props) => {
  const isSizeSmall = isEqual(size, 'small');

  return isSizeSmall ? (
    <div className='h-[185px] rounded-[8px] bg-secondary animate-pulse' />
  ) : (
    <div className='h-[230px] rounded-[8px] bg-secondary animate-pulse' />
  );
};
