import { yupResolver } from '@hookform/resolvers/yup';
import { IconLoading, IconPlus, IconSearch } from 'components/icons';
import FormTextInput from 'components/shared/form-text-input';
import { SaveRealEstateForm, saveRealEstateFormSchema } from 'forms';
import {
  CREATE_SAVED_ITEM,
  CREATE_USER_COLLECTION,
  CreateSavedItemData,
  CreateSavedItemVariables,
  CreateUserCollectionData,
  CreateUserCollectionVariables,
  DELETE_SAVED_ITEM,
  DeleteSavedItemData,
  DeleteSavedItemVariables,
  SavedItemType,
  UPDATE_SAVED_ITEM,
  UpdateSavedItemData,
  UpdateSavedItemVariables,
} from 'graphql/main/mutations';
import {
  Collection,
  CollectionEnum,
  GET_USER_COLLECTIONS_BY_TYPE,
  GetUserCollectionsByTypeData,
  GetUserCollectionsByTypeVariables,
  RealEstate,
} from 'graphql/main/queries';
import { useFlexMutation, useFlexQuery, useToast } from 'hooks';
import { isEmpty, isEqual, map } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import Modal from '../modal';
import ToastStack from '../toast-stack';

interface Props {
  realEstate?: RealEstate | null;
  onCompleteSaving?: (realEstate: string) => void;
  onClose?: () => void;
}

const SaveRealEstateModal = ({ realEstate, onCompleteSaving, onClose }: Props) => {
  const { saveItem, demandEstate, id, isSaved } = realEstate ?? {};
  const [showingCreateCollection, setShowingCreateCollection] = useState(false);
  const form = useForm<SaveRealEstateForm>({
    mode: 'onChange',
    resolver: yupResolver(saveRealEstateFormSchema),
    defaultValues: {
      search: undefined,
      collection: saveItem?.belongsToCollection?.id ?? undefined,
      newCollection: undefined,
    },
  });
  const searchWatch = useWatch({ control: form.control, name: 'search' });
  const collectionWatch = useWatch({ control: form.control, name: 'collection' });
  const newCollectionWatch = useWatch({ control: form.control, name: 'newCollection' });
  const collectionType = isEqual(demandEstate, 'estate')
    ? CollectionEnum.estate
    : isEqual(demandEstate, 'need_estate')
    ? CollectionEnum.need_estate
    : isEqual(demandEstate, 'lead_estate')
    ? CollectionEnum.lead_estate
    : CollectionEnum.estate;
  const {
    data: getUserCollectionsByTypeData,
    loading: loadingGetUserCollectionsByType,
    refetch: refetchGetUserCollectionsByType,
  } = useFlexQuery<GetUserCollectionsByTypeData, GetUserCollectionsByTypeVariables>(
    'main',
    GET_USER_COLLECTIONS_BY_TYPE,
    {
      variables: {
        type: collectionType,
        realEstateId: id,
      },
    },
  );
  const { getUserCollectionsByType: collections } = getUserCollectionsByTypeData || {};
  const [createCollection] = useFlexMutation<
    CreateUserCollectionData,
    CreateUserCollectionVariables
  >('main', CREATE_USER_COLLECTION);
  const [createSavedItem] = useFlexMutation<CreateSavedItemData, CreateSavedItemVariables>(
    'main',
    CREATE_SAVED_ITEM,
  );
  const [updateSavedItem] = useFlexMutation<UpdateSavedItemData, UpdateSavedItemVariables>(
    'main',
    UPDATE_SAVED_ITEM,
  );
  const [deleteSavedItem] = useFlexMutation<DeleteSavedItemData, DeleteSavedItemVariables>(
    'main',
    DELETE_SAVED_ITEM,
  );
  const { toasts, addToast, removeToast } = useToast();

  const selectCollection = (collection: Collection) => {
    setShowingCreateCollection(false);
    form.setValue('collection', collection.id);
  };
  const createSaved = async () => {
    if (id && collectionWatch) {
      const { errors: createSavedItemErrors } = await createSavedItem({
        variables: {
          type: SavedItemType[collectionType],
          belongsToCollection: collectionWatch,
          item: id,
        },
        onError: (error) => {
          addToast('error', error.message);
        },
      });
      if (createSavedItemErrors) {
        addToast('error', createSavedItemErrors[0].message);
      } else {
        onCompleteSaving?.(id);
        onClose?.();
      }
    }
  };
  const addSaved = async () => {
    if (saveItem?.id) {
      const { errors: updateSavedItemErrors } = await updateSavedItem({
        variables: {
          savedItemId: saveItem?.id,
          belongsToCollection: collectionWatch,
        },
        onError: (error) => {
          addToast('error', error.message);
        },
      });
      if (updateSavedItemErrors) {
        addToast('error', updateSavedItemErrors[0].message);
      } else {
        onCompleteSaving?.(saveItem?.id);
        onClose?.();
      }
    }
  };
  const removeSaved = async () => {
    if (saveItem?.id) {
      const { errors: deleteSavedItemErrors } = await deleteSavedItem({
        variables: {
          savedItemId: saveItem?.id,
        },
        onError: (error) => {
          addToast('error', error.message);
        },
      });
      if (deleteSavedItemErrors) {
        addToast('error', deleteSavedItemErrors[0].message);
      } else {
        onCompleteSaving?.(saveItem?.id);
        onClose?.();
      }
    }
  };
  const submit = async () => {
    if (id && newCollectionWatch) {
      const { errors: createCollectionErrors } = await createCollection({
        variables: {
          type: collectionType,
          name: newCollectionWatch,
          itemId: id,
        },
        onError: (error) => {
          addToast('error', error.message);
        },
      });
      if (createCollectionErrors) {
        addToast('error', createCollectionErrors[0].message);
      } else {
        onCompleteSaving?.(id);
        onClose?.();
      }
    }
  };

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      refetchGetUserCollectionsByType({
        type: collectionType,
        realEstateId: id,
        name: searchWatch,
      });
    }, 300);

    return () => clearTimeout(searchTimeout);
  }, [searchWatch]);

  return (
    <Fragment>
      <Modal closable title='Lưu tin rao' className='w-[400px]' onClose={onClose}>
        <div className='max-h-[540vh] w-full space-y-[28px] overflow-scroll'>
          <div className='flex flex-col space-y-[24px]'>
            <span className='select-none font-[600]'>Chọn bộ sưu tập để lưu</span>
            <FormProvider {...form}>
              <form
                id='collection-creation-form'
                autoComplete='off'
                className='flex flex-col space-y-[24px]'
                onSubmit={form.handleSubmit(submit)}
              >
                <FormTextInput
                  name='search'
                  label='Tìm kiếm bộ sưu tập'
                  endIcon={
                    loadingGetUserCollectionsByType ? (
                      <IconLoading className='min-h-[24px] min-w-[24px] animate-spin select-none text-primary' />
                    ) : (
                      <IconSearch className='min-h-[24px] min-w-[24px] cursor-pointer' />
                    )
                  }
                />
                <div className='h-[168px] overflow-y-auto'>
                  {!isEmpty(collections?.items) ? (
                    <ul id='collection-list' className='space-y-[12px]'>
                      {map(collections?.items, (collection, collectionIndex) => (
                        <li key={collectionIndex}>
                          <button
                            type='button'
                            className='flex h-[32px] w-full items-center space-x-[8px] transition duration-[200ms] ease-in-out hover:bg-secondary'
                            onClick={() => selectCollection(collection)}
                          >
                            <div
                              className={`min-h-[20px] min-w-[20px] rounded-full border ${
                                isEqual(collectionWatch, collection.id)
                                  ? 'relative border-primary before:absolute before:left-[2px] before:top-[2px] before:right-[2px] before:bottom-[2px] before:rounded-full before:bg-primary before:content-[""]'
                                  : 'border-stroke'
                              }`}
                            />
                            <span className='line-clamp-1'>
                              {`${collection.name} (${collection.totalItems})`}
                            </span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <span className='mt-[10px] italic text-text2'>Không tìm thấy bộ sưu tập</span>
                  )}
                </div>
                <div>
                  {showingCreateCollection ? (
                    <FormTextInput
                      required
                      name='newCollection'
                      maxLength={118}
                      error={form.formState.errors.newCollection}
                      label='Bộ sưu tập'
                      onChange={() => {
                        if (!isEmpty(form.getValues('newCollection')))
                          form.setValue('collection', '');
                      }}
                    />
                  ) : (
                    <button
                      type='button'
                      className='flex h-[40px] w-full items-center justify-center space-x-[10px] rounded-[8px] border border-dashed border-primary transition duration-[200ms] hover:bg-secondary'
                      onClick={() => setShowingCreateCollection(!showingCreateCollection)}
                    >
                      <IconPlus className='min-h-[24px] min-w-[24px] text-primary' />
                      <span className='font-[600] text-primary'>Tạo mới bộ sưu tập</span>
                    </button>
                  )}
                </div>
              </form>
            </FormProvider>
          </div>
          <div className='flex space-x-[12px]'>
            {showingCreateCollection ? (
              <Fragment>
                <button
                  type='button'
                  className='flex h-[40px] w-1/2 items-center justify-center space-x-[15px] rounded-[8px] bg-tertiary py-[12px] px-[28px] transition duration-[200ms] ease-in-out hover:bg-tertiary-light'
                  onClick={() => setShowingCreateCollection(!showingCreateCollection)}
                >
                  <span className='font-[600]'>Hủy tạo</span>
                </button>
                <button
                  form='collection-creation-form'
                  type='submit'
                  className='flex h-[40px] w-1/2 items-center justify-center space-x-[15px] rounded-[8px] bg-primary py-[12px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
                  onClick={form.handleSubmit(submit)}
                >
                  <span className='font-[600]'>Tạo bộ sưu tập</span>
                </button>
              </Fragment>
            ) : isSaved ? (
              <Fragment>
                <button
                  type='button'
                  className='flex h-[40px] w-1/2 items-center justify-center space-x-[15px] rounded-[8px] bg-tertiary py-[12px] px-[28px] transition duration-[200ms] ease-in-out hover:bg-tertiary-light'
                  onClick={removeSaved}
                >
                  <span className='font-[600]'>Hủy lưu</span>
                </button>
                <button
                  type='button'
                  className='flex h-[40px] w-1/2 items-center justify-center space-x-[15px] rounded-[8px] bg-primary py-[12px] px-[28px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light'
                  onClick={addSaved}
                >
                  <span className='font-[600]'>Cập nhật</span>
                </button>
              </Fragment>
            ) : (
              <button
                type='button'
                className='flex h-[40px] w-full items-center justify-center space-x-[15px] rounded-[8px] bg-primary py-[12px] px-[28px] text-paper transition duration-[200ms] ease-in-out hover:bg-primary-light disabled:opacity-50'
                onClick={createSaved}
                disabled={!collectionWatch}
              >
                <span className='font-[600]'>Xác nhận</span>
              </button>
            )}
          </div>
        </div>
      </Modal>
      <ToastStack toasts={toasts} removeToast={removeToast} />
    </Fragment>
  );
};

export default SaveRealEstateModal;
